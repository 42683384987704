<template>
  <div id="accountBank">
    <div v-if="loaded">
      <!-- BANK TABLE -->
      <b-card no-body class="border mt-1">
        <b-table
            ref="propertyDetails-table"
            striped
            small
            responsive
            v-bind:items="cptItems"
            v-bind:fields="fields"
            :busy="!loaded"

        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>

          <template #cell(id)="data">
            <b-button v-if="data.item.buildingId==null" style="width: 95px" size="sm"
                      class="btn btn-danger d-block mt-1 mb-1">
              {{ msg('Please reinsert') }}
            </b-button>

            <b-button style="width: 95px" size="sm" @click="info(data.item, data.index, $event.target)"
                      class="btn btn-warning d-block mt-1 mb-1">
              {{ msg('delete') }}
            </b-button>

            <b-button style="width: 95px;white-space: nowrap" size="sm"
                      @click="goToRelaEstate(data.item, data.index, $event.target)"
                      class="btn btm-info d-block mt-1 mb-1">
              {{ msg('Real estate') }}
            </b-button>
          </template>
          <template #cell(address)="data">
            <div>
              <div style="white-space: nowrap">
                <span class="d-inline-block">{{ getFullAddress(data.item) }}</span>
              </div>
              <div style="margin-left: 5px">
                <strong>{{ msg('Zip') }}: </strong>{{ data.item.zip }}
              </div>
            </div>
          </template>
          <template #cell(realEstateTreasure.keyCode)="data">
            <div>
              <span v-if="!showFullText">{{ data.value.substring(0, maxLength) }}...</span>
              <span v-else>{{ data.value }}</span>
              <b-link v-if="!showFullText" @click="showText(data.item)">Read more</b-link>
            </div>
          </template>
          <template #cell(realEstateArea.totalSpace)="data">
            {{ data.value > 0 ? data.value + ' m²' : 'No data' }}
          </template>
          <template #cell(realEstateArea.balconyTerraceSpace)="data">
            {{ data.value > 0 ? data.value + ' m²' : 'No data' }}
          </template>
          <template #cell(realEstatePrices.price)="data">
            {{ convertToEURO(data.value) }}
          </template>


        </b-table>
      </b-card>
      <b-modal v-model="showPopup" title="Read More">
        <div>{{ selectedText }}</div>
        <template #modal-footer>
          <b-button variant="primary" @click="showPopup = false">Close</b-button>
        </template>
      </b-modal>
      <b-modal :id="infoModal.id" :title="msg('Confirmation')" @ok="archiveRealEstateById(infoModal.propertyId)"
               ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetInfoModal">
        <p class="my-4">{{ msg('areyousureyouwanttotakethisrealestateout') }}</p>
      </b-modal>
    </div>
    <div v-else-if="this.error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <b-spinner label="Spinning"/>
      {{ msg('loading') }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'buildingProperties',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },

    historyMaxHeight: {
      default: 500,
      type: Number
    },
    disableAddBank: {
      required: false,
      type: Boolean
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.loadProperties()
      },
      deep: true
    }
  },
  data: () => ({
    loaded: false,
    infoModal: {
      id: 'info2-modal',
      title: '',
      content: ''
    },
    selectedText: null,
    showFullText: false,
    maxLength: 50, // Change this to adjust the number of characters shown initially
    showPopup: false,
    error: '',
    currentActiveBankId: null,
    detailsRow: null,
    propertyList: [],
    currentPropertyList: [],
    filter: {
      like: {search: '', name: "", shortDescription: ""},
      eq: {type: "", realEstateArea: {}, featureStatus: null},
      lte: {realEstatePrices: {}},
      gte: {realEstatePrices: {}},
      in: {}
    },
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),

  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', ['getUserFullName', 'getUsersByRole', 'getAllUsers', 'getUser']),
    ...mapGetters('building', ['fileTags']),
    ...mapGetters('realEstate', ["getFullAddress"]),

    editableFields() {
      return this.fields.filter(field => field.editable)
    },
    building: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    cptItems() {
      return this.propertyList.map((item) => {
        let tmp = item;
        item.buildingId != null ? tmp.variant = 'success' : tmp.variant = 'danger';
        console.log("item", tmp)
        return tmp;

      })
    },
    fields() {
      let $this = this
      return [

        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true
          , editable: true

        },
        {
          key: 'realEstateArea.totalSpace',
          label: $this.msg('Space'),
          sortable: false, editable: true

        },

        {
          key: 'realEstateArea.rooms',
          label: $this.msg('rooms'),
          sortable: true, editable: true

        },

        {
          key: 'realEstateTreasure.keyCode',
          label: $this.msg('CheckIn details'),
          sortable: true
        },


        {
          key: 'id',
          label: $this.msg('Action'),
          sortable: false
        },
      ]
    },

  },
  created() {

    this.loadProperties()

  },
  beforeDestroy() {
    //  this.$root.$off('crm::clientId::bank::load', this.listeners.load)
  },
  methods: {
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('building', ['setBankToBuilding', 'updateBankDocumentFile', 'removeProperty','addProperty']),
    ...mapActions('realEstate', {_loadPropertiesList: 'getAllForBuildings'}),
    loadProperties() {
      if (this.building != null && this.building.propertyIds != null && this.building.propertyIds.length > 0) {
        this.filter["in"]["id"] = this.building.propertyIds;
        Promise.all([this._loadPropertiesList({amount: 9999, filter: this.filter}).then(ans => {
          this.propertyList = ans["RealEstates"];
        })])
            .finally(() => this.loaded = true)

      } else this.loaded = true;

    },
    showText(item) {
      console.log("im hereeeeeeeeeeeee")
      this.selectedText = item.realEstateTreasure.keyCode;
      this.showPopup = true;

    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    goToRelaEstate(item, index, button) {
      this.$router.push(`/real_estate/edit/${item.id}`);
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    archiveRealEstateById(realId) {
      let indexToRemove = this.building.propertyIds.findIndex(id => {
        return id === realId;
      });

      this.building.propertyIds.splice(indexToRemove)

      this.removeProperty({buildingId: this.building.id,propertyId: realId}
      ).then(ans => {
        this.building = ans;
        this.resetInfoModal()
      })
    },

    onValueUpdated(args) {

      this.$emit('change', args)
    }
    ,


    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    }
    ,


    save() {
      let $this = this
      this.setBankToBuilding({id: this.building.id, newBank: this.newBank})
          .then(ans => {

                $this.building = ans;
                $this.currentBankList = ans.activeBanks;
              }
          )
          .finally(() => {
            $this.resetNewBank()
            $this.dismissPopover()
          })
    }
    ,

  }
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
</style>
