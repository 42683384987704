<template>
  <b-modal v-model="dialog.active" size="lg" centered hide-footer no-close-on-backdrop body-class="p-0"
           @hidden="reset">
    <template #modal-title>{{ msg("Add Guest Feature") }}</template>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img  :src="features.selected.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="msg('Name')">
                    <v-select
                        :loading="features.loading"
                        :options="features.items"
                        v-model="features.selected"
                        label="name"
                        @search="searchFeatures"
                        @input="onFeatureSelected"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :label="msg('Description')">
                    <b-textarea v-model="features.selected.description" disabled placeholder="Description"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="msg('Image Uri')">
            <b-input v-model="features.selected.imageUri" disabled placeholder="Image Uri"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="msg('Rate')">
            <b-input-group prepend="€" >
              <b-input number v-model="realEstateGuestFeature.rate" v-bind:disabled="!realEstateGuestFeature.featureId" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="features.selected.amountAllowed">
          <b-form-group>
            <b-form-checkbox disabled  v-model="features.selected.amountAllowed">
              {{ msg('Amount Allowed') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="features.selected.autoApprove">
          <b-form-group>
            <b-form-checkbox disabled v-model="features.selected.autoApprove">
              {{ msg('Auto Approve') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="features.selected.dailyPayment">
          <b-form-group>
            <b-form-checkbox disabled v-model="features.selected.dailyPayment">
              {{ msg('Daily Payment') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="features.selected.active">
          <b-form-group>
            <b-form-checkbox disabled v-model="features.selected.active">
              {{ msg('Active') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-btn block @click="reset" variant="outline-primary" >{{ msg('Cancel') }}</b-btn>
        </b-col>
        <b-col cols="6">
          <b-btn block @click="saveFeature" variant="primary" v-bind:disabled="loading || !canSave">{{ msg('Add') }}</b-btn>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

const getRealEstateFeature = () => {
  return {
    featureId: null, rate: null
  }
}

export default {
  name: "GuestFeatureModalRealEstate",
  data: () => ({
    filter: {},
    page: {amount: 10, currentPage: 1, total: 0},
    dialog: {
      active: false,
    },
    loading: false,
    features: {
      buids: null,
      loading: false,
      items: [],
      selected: {},
    },
    realEstateGuestFeature: getRealEstateFeature(),
  }),
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    canSave() {
      return this.realEstateGuestFeature.featureId != null &&
          this.realEstateGuestFeature.featureId.trim() !== "" &&
          this.realEstateGuestFeature.rate != null &&
          this.realEstateGuestFeature.rate !== "" &&
          !isNaN(this.realEstateGuestFeature.rate) &&
          parseFloat(this.realEstateGuestFeature.rate) > 0;
    }
  },
  methods: {
    ...mapActions('guestFeature',['fetchAllGuestFeature', 'insertGuestFeature', 'updateGuestFeature', 'fetchGuestFeatureById']),

    refresh() {

    },
    searchFeatures(text,loading) {
      let $this = this;
      let filter = {name: text};
      this.features.loading = true;
      loading(true);
      this.fetchAllGuestFeature({filter: filter, buids: this.features.buids, page: 1, amount: 10}).then(data => {
        $this.features.items = data.Features;
      }).finally(() => {
        $this.features.loading = false;
        loading(false);
      })
    },
    addNewFeature(buid) {
      this.reset();
      this.features.buids = [buid];
      this.dialog.active = true;
    },
    closeModal() {
      this.dialog.active = false;
      this.reset();
    },
    onFeatureSelected() {
      if(this.features.selected == null || this.features.selected.id == null){
        this.realEstateGuestFeature.featureId = null;
      }else {
        this.realEstateGuestFeature.featureId = this.features.selected.id;
      }
    },
    reset() {
      this.features.selected = {};
      this.realEstateGuestFeature = getRealEstateFeature();
    },
    saveFeature() {
      this.$emit('save', this.realEstateGuestFeature);
    }
  }
}
</script>


<style scoped>

</style>
