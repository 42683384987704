<template>
  <div>
    <b-row>
      <b-col  cols="6" sm="6" md="4" lg="3" v-for="feature in selectedFeatures" v-bind:key="feature.id">
        <b-card>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="removeFeature(feature)">
              <feather-icon icon="TrashIcon" size="16"/>
            </b-link>
          </div>
          <div class="text-center mb-1" style="min-height: 150px">
            <b-img :src="feature.imageUri" alt="Image" top style="height: 150px; width: auto"></b-img>
          </div>
          <h5 class="card-title mb-1">{{feature.name}}</h5>
          <div>
            {{feature.description}}
          </div>
          <div>
            <b-badge style="display: block" variant="primary">
              <span>{{feature.rate}} €</span>
              <span v-if="feature.dailyPayment"> / {{msg('peer day')}}</span>
            </b-badge>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <guest-feature-modal-real-estate ref="guestFeatureModal" @save="onNewFeature"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import GuestFeatureModalRealEstate from "@/components/modals/feature/GuestFeatureModalRealEstate.vue";

export default {
  name: "realEstateFeature",
  components: {GuestFeatureModalRealEstate},
  data: () => ({
    loading: true,
    allFeaturesMap: {},
  }),
  props: {
    buid: {
      required: true,
    },
    value: {
      required: true,
    }
  },
  mounted() {
    this.refresh();
  },
  computed: {
    features: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    selectedFeatures() {
      let $this = this;
      return (this.features || [])
          .filter(f=>$this.allFeaturesMap[f.featureId] != null).map(f=>({...f, ...$this.allFeaturesMap[f.featureId]}));
    }
  },
  methods: {
    ...mapActions('guestFeature', ['fetchGuestFeaturesByBuid']),
    refresh() {
      let $this = this;
      $this.loading = true;
      console.log("refreshing features")
      this.fetchGuestFeaturesByBuid({buid: this.buid}).then(data => {
        $this.allFeaturesMap = data.Features.toObject('id');
      }).finally(() => {
        $this.loading = false;
      })
    },
    addNewFeature() {
      this.$refs.guestFeatureModal.addNewFeature(this.buid);
    },
    onNewFeature(feature) {
      let features = [...(this.features || [])];
      let currentIndex = features.findIndex(f => f.featureId === feature.featureId);
      if (currentIndex >= 0)
        features[currentIndex] = feature;
      else
        features.push(feature);
      this.features = features;
      this.$refs.guestFeatureModal.closeModal();
    },
    removeFeature(feature) {
      let features = [...(this.features || [])];
      let currentIndex = features.findIndex(f => f.featureId === feature.featureId);
      if (currentIndex >= 0)
        features.splice(currentIndex, 1);
      this.features = features;
    }
  }
}
</script>


<style scoped>

</style>
